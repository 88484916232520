import { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../context'
import { getMeetingRoomsBooksCalendar } from '../api/meet-rooms'
import { dayNames, monthNames } from '../utils/CalendarDataTime'
import MonthSelector from '../components/MonthSelector'
import Filter from '../components/Filter'
import RoomDay from '../components/RoomDay'
import Calendar from '../components/Calendar'
import RoomsReservationOverlay from '../components/RoomsReservationOverlay'
import RoomsAdminReservationModal from '../components/RoomsAdminReservationModal'
import RoomsUserReservationModal from '../components/RoomsUserReservationModal'
import RoomReservationCancelModal from '../components/modals/RoomReservationCancelModal'
import { getHolidays } from '../api/admin-settings'


const RoomsCalendar = () => {
  const { token, isAdmin, meetRoomsTime, rooms } = useContext(AppContext)

  // Calendar States
  const [days, setDays] = useState([])
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [hours, setHours] = useState([])
  const [disabledHours, setDisabledHours] = useState([])
  const [reservations, setReservations] = useState([])
  // Server Time State
  const [serverTime, setServerTime] = useState('')
  // Interval State
  const [refetchInterval, setRefetchInterval] = useState(null)
  // Filter States
  const [filter, setFilter] = useState(null)
  // Reservation Modal State
  const [reservationModal, setReservationModal] = useState({
    show: false,
    initialData: {}
  })
  const [calendarButton, setCalendarButton] = useState(false)
  // Overlay States
  const [showReservationOverlay, setShowReservationOverlay] = useState(false)
  const [overlayData, setOverlayData] = useState({})
  // Cancel Reservation Modal State
  const [cancelReservationModal, setCancelReservationModal] = useState({
    show: false,
    step: 1,
    reservation: null
  })

  const [isLoading, setIsLoading] = useState(false)

  // Refs to scroll to current day
  const calendarRef = useRef(null)
  const hoursRef = useRef(null)
  const currentDayRef = useRef(null)

  // Set default data
  const filters = rooms.map(item => `Sala ${item.name.toLowerCase()}`)
  filters.unshift('Todas las salas')
  const fixedHours = hours.slice(0, -1)


  // Set rooms and hours
  useEffect(() => {
    const getData = async () => {
      // Set calendar hours
      const initialHour = Number(meetRoomsTime.startTime.slice(0, 2))
      const finishHour = Number(meetRoomsTime.endTime.slice(0, 2))

      const hoursArray = []
      for (let i = initialHour; i <= finishHour; i++) {
        const hour = i < 10 ? `0${i}:00` : `${i}:00`
        hoursArray.push(hour)
        if(i !== finishHour + 1) {
          const halfHour = i < 10 ? `0${i}:30` : `${i}:30`
          hoursArray.push(halfHour)
        }
      }

      setHours(hoursArray)

      // Set disabled hours in current day
      const time = new Date(serverTime)
      const newDisabledHours = []

      for (let index = 0; index < hoursArray.length; index++) {
        const hourTime = new Date()
        hourTime.setHours(hoursArray[index].split(':')[0], hoursArray[index].split(':')[1], 0, 0)

        const timeLimit = 1200000

        if(time.getTime() - timeLimit > hourTime.getTime()) {
          newDisabledHours.push(hoursArray[index])
        }
      }

      setDisabledHours(newDisabledHours)
    }

    if(serverTime) getData()
  }, [serverTime])

  // Get reservations
  useEffect(() => {
    if(token) {
      setIsLoading(true)
      getReservations()
    }
  }, [token, month])

  // Refresh reservations every 30 seconds
  useEffect(() => {
    if(reservationModal.show) {
      clearInterval(refetchInterval)
    } else {
      const newInterval = setInterval(() => {
        getReservations()
      }, 30000)
      setRefetchInterval(newInterval)
    }

    return () => clearInterval(refetchInterval)
  }, [reservationModal.show])

  // Set month days
  useEffect(() => {
    if(serverTime) getCalendarData()
  }, [month, reservations, serverTime])

  // Scroll to current day
  useEffect(() => {
    if(calendarRef.current && hoursRef.current && currentDayRef.current) {
      const pixelsToScroll = currentDayRef.current.offsetTop
      const hoursHeight = hoursRef.current.offsetHeight

      calendarRef.current.scroll(0, (pixelsToScroll - hoursHeight))
    } else {
      calendarRef.current.scroll(0, 0)

    }
  }, [calendarRef.current, hoursRef.current, currentDayRef.current, month])

  // Open reservation modal
  useEffect(() => {
    if(calendarButton) {
      openReservationModal({})
      setCalendarButton(false)
    }
  }, [days])


  // Get calendar data funtions
  const getReservations = async () => {
    const data = await getMeetingRoomsBooksCalendar(token, {
      month: month + 1,
      year
    })
    const normalizedReservations = []

    const normalizeDateData = Object.keys(data?.data).map(key => ({
      date: key,
      data: data?.data[key]
    }))

    // Normalize hours
    const normalizeTime = (time) => {
      const hours = time.getHours()
      const minutes = time.getMinutes()

      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    }

    normalizeDateData.map(item => {
      const dateData = item.data

      Object.keys(dateData).map(key => {
        const room = key

        Object.keys(dateData[key]).forEach(id => {
          normalizedReservations.push({
            date: item.date,
            id: Number(id),
            room,
            start_hour: normalizeTime(new Date(dateData[key][id].start_date)),
            end_hour: normalizeTime(new Date(dateData[key][id].end_date)),
            ...dateData[key][id]
          })
        })

      })
    })

    setReservations(normalizedReservations)
    setServerTime(data?.server_time)
  }
  const getCalendarData = async () => {
    // Get days of the month
    function getDaysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate()
    }
    const daysInMonth = getDaysInMonth(year, month)

    let currentDayIsWeekend = {
      isWeekend: false,
      day: null
    }

    const holidays = await getHolidays(token, {
      month: month + 1,
      year
    })

    // Set new days
    const newDays = []
    for(let i = 1; i < daysInMonth + 1; i++) {
      const date = new Date(year, month, i)

      // Translate the names of the days
      let dayName = date.toString().slice(0, 3)
      dayName = dayNames[dayName]

      // Check if the day is weekend
      let isWeekendDay = false
      const dayWeekNumber = date.getDay()
      if(dayWeekNumber === 0 || dayWeekNumber === 6) {
        isWeekendDay = true
      }

      // Disable holidays
      let isDisabled = false
      const isHoliday = holidays.find(item => item.date == `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${i < 10 ? '0' + i : i}`)
      if(isHoliday) {
        isDisabled = true
      }

      // Check if current day
      const dateTime = date.getTime()
      const currentDate = new Date(serverTime)
      currentDate.setHours(0, 0, 0, 0)
      const currentDateTime = currentDate.getTime()
      const isCurrentDay = dateTime === currentDateTime

      // Chek if current day is weekend
      if(isCurrentDay && isWeekendDay) {
        currentDayIsWeekend = {
          isWeekend: true,
          day: i
        }
      }

      // Set scroll day when current day is weekend
      let isScrollDay = false
      if(currentDayIsWeekend.isWeekend && dayWeekNumber === 1) {
        currentDayIsWeekend = {
          isWeekend: false,
          day: null
        }
        isScrollDay = true
      }

      // Check if is a past day
      const isPastDay = dateTime < currentDateTime

      // Check if is a limit day (30 days after the current day)
      const milisecondsInDay = 86400000
      const limitTime = currentDateTime + (30 * milisecondsInDay)
      const isLimitDay = dateTime > limitTime

      // Get reservation data for the day
      const formatDate = `${year}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${i < 10 ? '0' + i : i}`
      const dayData = reservations.filter(item => item.date == formatDate)

      newDays.push({
        data: dayData.length > 0 ? dayData : [],
        date: formatDate,
        dayWeekName: dayName,
        dayWeekNumber,
        dayNumber: i,
        isCurrentDay,
        isWeekendDay,
        isScrollDay,
        isPastDay,
        isLimitDay,
        isDisabled
      })
    }

    // Fill calendar with empty days
    let firstMonthDay = newDays[0].dayWeekNumber

    if(firstMonthDay === 0) {
      firstMonthDay = 7
    }
    for(let i = 1; i < firstMonthDay; i++) {
      const dayNumber = new Date(year, month, -i + 1).getDate()

      newDays.unshift({
        dayWeekName: '',
        dayWeekNumber: '',
        dayNumber,
        isDisabled: false,
        isFill: true
      })
    }

    setDays(newDays)
    setIsLoading(false)
  }

  // Calendar actions
  const changeMonth = (direction) => {
    if(direction === 'next') {
      if(month === 11) {
        setMonth(0)
        setYear(year + 1)
      } else {
        setMonth(month + 1)
      }
    } else {
      if(month === 0) {
        setMonth(11)
        setYear(year - 1)
      } else {
        setMonth(month - 1)
      }
    }
  }
  const changeFilter = (filter) => {
    if(filter === 0) {
      setFilter(null)
    } else {
      setFilter(filter)
    }
  }
  const handleCreateReservationButton = () => {
    const currentDate = new Date(serverTime)
    if(month == currentDate.getMonth() && year == currentDate.getFullYear()) {
      openReservationModal({})
    } else {
      setMonth(currentDate.getMonth())
      setYear(currentDate.getFullYear())
      setCalendarButton(true)
    }
  }

  // Overlay toggle
  const openReservationOverlay = ({ data, dayWeekName, dayNumber, isCurrentDay, isPastDay, initialFilter }) => {
    setOverlayData({
      data,
      dayWeekName,
      dayNumber,
      isCurrentDay,
      isPastDay,
      initialFilter
    })
    setShowReservationOverlay(true)
  }
  const closeReservationOverlay = () => setShowReservationOverlay(false)

  // Reservation modal toggle
  const openReservationModal = ({ room_id, room_name, start_hour, end_hour, data, dayWeekName, dayNumber, isPastDay }) => {
    if(dayWeekName && dayNumber) {
      setReservationModal({
        show: true,
        initialData: {
          room_id,
          room_name,
          start_hour,
          end_hour,
          data,
          dayWeekName,
          dayNumber,
          month,
          year,
          isPastDay
        }
      })
    } else {
      // Open modal with empty data
      const currentDate = new Date(serverTime)
      const currentDay = days.find(day => day.dayNumber === currentDate.getDate() && month === currentDate.getMonth() && year === currentDate.getFullYear())

      setReservationModal({
        show: true,
        initialData: {
          room_id,
          room_name,
          start_hour,
          end_hour,
          data: currentDay.data,
          dayWeekName: currentDay.dayWeekName,
          dayNumber: currentDay.dayNumber,
          month,
          year,
          isPastDay: currentDay.isPastDay
        }
      })
    }
  }
  const closeReservationModal = () => {
    setReservationModal({
      show: false,
      initialData: null
    })
  }

  // Cancel reservation modal toggle
  const openCancelReservationModal = (reservation, isCurrentDay) => {
    setCancelReservationModal({
      show: true,
      step: 1,
      reservation,
      isCurrentDay
    })
  }
  const closeCancelReservationModal = () => {
    setCancelReservationModal({
      show: false,
      step: 1,
      reservation: null
    })
  }


  return (
    <>
      <section className="RoomsCalendar d-none d-lg-flex">
        <header className="RoomsCalendar__Header">
          <button className="button-green-panel" onClick={handleCreateReservationButton}>
            <FontAwesomeIcon icon={faPlus}/>
            Crear reserva
          </button>

          {isAdmin && (
            <Link className="button-inverted-panel" to='deshabilitar'>
              Deshabilitar día
            </Link>
          )}

          <nav className="RoomsCalendar__Nav">
            <button
              className="RoomsCalendar__Nav-Button pe-1"
              onClick={() => changeMonth('prev')}
              title="Mes pasado"
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faLessThan}/>
            </button>

            <MonthSelector data={monthNames} optionSelected={monthNames[month]} onChange={setMonth}/>

            <button
              className="RoomsCalendar__Nav-Button ps-1"
              onClick={() => changeMonth('next')}
              title="Mes siguiente"
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faGreaterThan}/>
            </button>
          </nav>

          <h2 className="RoomsCalendar__Header-Year text-2 ms-2">{ year.toString() }</h2>

          <Filter data={filters} optionSelected={filters[filter || 0]} defaultOption="Todas las salas" onChange={changeFilter}/>
        </header>

        <div className="RoomsCalendar__Content" ref={calendarRef}>
          <div className="RoomsCalendar__Hours" ref={hoursRef}>
            {fixedHours.map((hour) => (
              <div className="RoomsCalendar__Hour" key={`Rooms-Calendar-Hour-${hour}`}>
                {hour}
              </div>
            ))}
          </div>

          {days.map((day) => {
            if(day.isWeekendDay || day.isFill) return

            if(day.isCurrentDay) return (
              <RoomDay
                key={`Room-Day-${day.dayWeekName}-${day.dayNumber}`}
                currentDayRef={currentDayRef}
                hours={hours}
                disabledHours={disabledHours}
                filter={filter}
                openOverlay={openReservationOverlay}
                openModal={openReservationModal}
                closeOverlay={closeReservationOverlay}
                {...day}
              />
            )
            if(day.isScrollDay) return (
              <RoomDay
                key={`Room-Day-${day.dayWeekName}-${day.dayNumber}`}
                currentDayRef={currentDayRef}
                hours={hours}
                disabledHours={disabledHours}
                filter={filter}
                openOverlay={openReservationOverlay}
                openModal={openReservationModal}
                closeOverlay={closeReservationOverlay}
                {...day}
              />
            )
            else return (
              <RoomDay
                key={`Room-Day-${day.dayWeekName}-${day.dayNumber}`}
                hours={hours}
                filter={filter}
                openOverlay={openReservationOverlay}
                openModal={openReservationModal}
                closeOverlay={closeReservationOverlay}
                {...day}
              />
            )
          })}
        </div>
      </section>

      <Calendar
        type="rooms"
        days={days}
        month={month}
        year={year}
        changeMonth={changeMonth}
        setMonth={setMonth}
        openModal={openReservationModal}
        openOverlay={openReservationOverlay}
        closeOverlay={closeReservationOverlay}
      />

      <RoomsReservationOverlay
        showOverlay={showReservationOverlay}
        closeOverlay={closeReservationOverlay}
        openModal={openReservationModal}
        openCancelModal={openCancelReservationModal}
        serverTime={serverTime}
        {...overlayData}
      />

      {cancelReservationModal.show && (
        <RoomReservationCancelModal
          modalState={cancelReservationModal}
          setModalState={setCancelReservationModal}
          closeModal={closeCancelReservationModal}
          refreshData={getReservations}
          serverTime={serverTime}
        />
      )}

      {reservationModal.show && isAdmin
        ? <RoomsAdminReservationModal
          days={days}
          hours={hours}
          initialData={reservationModal.initialData}
          refreshData={getReservations}
          changeMonth={changeMonth}
          closeModal={closeReservationModal}
          serverTime={serverTime}
        />
        : reservationModal.show && <RoomsUserReservationModal
          days={days}
          hours={hours}
          initialData={reservationModal.initialData}
          refreshData={getReservations}
          changeMonth={changeMonth}
          closeModal={closeReservationModal}
          serverTime={serverTime}
        />
      }
    </>
  )
}

export default RoomsCalendar